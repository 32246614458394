<template>
  <b-card title="" style="padding: 2rem !important">
    <!-- {{ users }} -->
    <b-row>
      <b-col cols="6">
        <h3 class="text-primary">Edit User</h3>
      </b-col>
      <b-col cols="6">
        <b-button
          variant="primary"
          class="btn btn-primary float-right"
          @click="back()"
          size="sm"
        >
          Back
        </b-button>
      </b-col>

      <b-col cols="12">
        <validation-observer ref="simpleRules" #default="{ invalid }">
          <b-form @submit.prevent>
            <b-row>
              
              <!-- email -->
              <b-col cols="4">
                <b-form-group label="Email" label-for="v-email">
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="v-email"
                      type="email"
                      placeholder="Email"
                      v-model="email"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- first name -->
              <b-col cols="4">
                <b-form-group label="First Name" label-for="v-first-name">
                  <b-form-input
                    id="v-first-name"
                    placeholder="First Name"
                    v-model="firstname"
                  />
                </b-form-group>
              </b-col>
              <!-- last name -->
              <b-col cols="4">
                <b-form-group label="Last Name" label-for="v-last-name">
                  <b-form-input
                    id="v-last-name"
                    placeholder="Last Name"
                    v-model="lastname"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Groups" label-for="v-first-name">
                  <v-select
                    v-model="groups"
                    multiple
                    :options="groupOptions"
                    label="name"
                    taggable
                  />
                </b-form-group>
                <!-- {{groups}} -->
              </b-col>
              <b-col cols="6">
                <b-form-group label="Application" label-for="v-first-name">
                  <v-select
                    v-model="applications"
                    multiple
                    :options="app_options"
                    label="application_name"
                    taggable
                  />
                </b-form-group>
              </b-col>
              <!-- submit and reset -->
              <b-col cols="12" class="mt-1">
                <b-button
                  type="submit"
                  variant="primary"
                  class="mr-1"
                  @click="updateUser(userid)"
                  :disabled="invalid"
                >
                  Submit
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BCard,
  BForm,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BFormSelectOption,
} from "bootstrap-vue";
// import axiosIns from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import vSelect from "vue-select";
export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormSelectOption,
  },
  data() {
    return {
      lastname: "",
      firstname: "",
      email: "",
      groups: "",
      groupOptionss: [],
      groupOptions: [],
      applications: "",
      applicationOptions: [],
      token: "",
      items: [],
      app_options: [],
      userid: "",
      users: "",
      // username:"",
      // groups:"",
    };
  },
  computed: {},
  // mounted() {
  //   // (this.tenantid = this.$store.getters.tenantid),
  //   //   (this.accesstoken = this.$store.getters.token),
    
  // },
  created() {
    this.fetchGroup();
    this.fetchApplication();
    this.userid = this.$route.params.id;
    this.getUser(this.$route.params.id);
  },
  methods: {
    back() {
      this.$router.push("/eca-user");
    },
    fetchGroup() {
      const options = {
        method: "GET",
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("JWTtoken")}`,
        //   "content-type": "application/json",
        // },
        url: process.env.VUE_APP_BASEURL + "user/groups/",
      };
      this.$http(options)
        .then((res) => {
          this.groupOptionss = res.data;
          this.groupOptionss.forEach((ele) => {
            this.groupOptions.push(ele);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchApplication: function () {
      const options = {
        method: "GET",
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("JWTtoken")}`,
        //   "content-type": "application/json",
        // },
        url: process.env.VUE_APP_BASEURL + "application/applications/",
      };
      this.$http(options).then((res) => {
        this.items = res.data;
        this.applicationOptions = res.data.results;
        this.applicationOptions.forEach((ele) => {
          this.app_options.push(ele);
        });
      });
    },
    getUser(userid) {
      const options = {
        method: "GET",
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("JWTtoken")}`,
        //   "content-type": "application/json",
        // },
        url: process.env.VUE_APP_BASEURL + `user/users/${userid}`,
      };
      var self = this;
      this.$http(options)
        .then((res) => {
          console.log(res.data);
          this.users = res.data;
          this.firstname = res.data.first_name;
          this.lastname = res.data.last_name;
          this.email = res.data.email;
          this.applications = res.data.applications;
          this.groups = res.data.groups;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    gotoAdduser() {
      this.$router.push("/eca-user");
    },

    updateUser(userid) {
      var self = this;
      let data = {
        first_name: this.firstname,
        last_name: this.lastname,
        email: this.email,
        username: this.email,
        applications: this.applications,
        groups: this.groups,
        id: this.userid,
      };
      // if (this.groups) {
      //   let groups_list = [];
      //   groups_list.push(this.groups);
      //   data["groups"] = groups_list;
      // }
      const options = {
        method: "PUT",
        data: data,
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("JWTtoken")}`,
        //   "content-type": "application/json",
        // },
        url: process.env.VUE_APP_BASEURL + `user/users/${this.userid}/`,
      };
      this.$http(options)
        .then((res) => {
          if (res.data.status === "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Edited Successfully",
                icon: "EditIcon",
                variant: "success",
              },
            });
            this.$router.push("/eca-user");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // updateUser(user) {
    //   var self = this;
    //   let data = {
    //     firstname: this.firstname,
    //     lastname: this.lastname,
    //     email: this.email,
    //     password: this.password,
    //     id: user._id,
    //   };
    //   const options = {
    //     method: "PUT",
    //     data: data,
    //     headers: { "content-type": "application/json" },
    //     url: process.env.VUE_APP_SSO_API_URL + `adminuser/update/${user._id}`,
    //   };
    //   this.$http(options)
    //     .then((res) => {
    //       console.log(res);

    //       self
    //         .$toast({
    //           component: ToastificationContent,
    //           props: {
    //             title: "Successfully updated the user",
    //             icon: "EditIcon",
    //             variant: "success",
    //           },
    //         })
    //         .then(
    //           (response) => {
    //             this.$router.push({ name: "users" });
    //           },
    //           (error) => {
    //             console.error(
    //               "Got nothing from server. Prompt user to check internet connection and try again"
    //             );
    //           }
    //         );
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
 